
import { CancelationReasonsEnum, ApiOrdersProductsMsisdnCancelDeleteRequest, CancelationReasonsDto, OrderProductsDto, OrderProductStatusesEnum, OrdersViewModel, ProductsListViewModel } from "@/api-client";
import { Component, Vue } from "vue-property-decorator";
import { PropType } from "vue/types/v3-component-props";
import { formatDate } from "@/utils/formatDate";
import DialogBox from "@/components/DialogBox.vue";
import { migrateDdlGet, topUpDdlGet, cancelDdlGet } from "@/api/products";
import { ordersProductCancel, ordersProductMigratePut, ordersProductSuspend, ordersProductTopUpPut } from "@/api/orders";
import { Message } from "element-ui";
import { hasClaim } from "@/utils/claims";
import { formatName, orderProductStatusesFilter } from "@/utils";

type ActionName = "topUp" | "migrate" | "cancel" | "suspend";
type ActionDetail = {
  callback: Function;
  message: string;
  title: string;
  ddl: ProductsListViewModel[] | CancelationReasonsDto[] | null;
}

@Component({
  name: "Products",
  components: { DialogBox },
  props: {
    data: { required: true, type: Object as PropType<OrdersViewModel> },
  },
  filters: {
    statusFilter: (status:OrderProductStatusesEnum) => orderProductStatusesFilter(status)
  }
})
export default class extends Vue {
  filterReference = "";
  dialogVisible: boolean = false;
  dialogSelectedDdlId: number | string | null = null;
  dialogSelectedProduct: OrderProductsDto | null = null;
  dialogSelectedAction: ActionName | null = null;
  actionTitle: string = "";
  actions: Record<ActionName, ActionDetail> = {
    "topUp": {
      callback:() => this.topUp(),
      message: "",
      title: "Top Up",
      ddl: []
    },
    "migrate": {
      callback:() => this.migrate(),
      message: "",
      title: "Upgrade / Downgrade",
      ddl: []
    },
    "cancel": {
      callback:() => this.cancel(),
      message: "",
      title: "Cancel",
      ddl: []
    },
    "suspend": {
      callback:() => this.suspend(),
      message: "",
      title: "Suspend",
      ddl: null
    }
  }
  userHasPermisson = false;

  getCancelationReasonEnum(id: number): CancelationReasonsEnum | null {  
  const mapping: Record<number, CancelationReasonsEnum> = {  
    1: CancelationReasonsEnum.NoLongerUsesProduct,  
    2: CancelationReasonsEnum.CannotAfford,  
    3: CancelationReasonsEnum.PoorServiceOrCoverage,  
    4: CancelationReasonsEnum.NonPayment,  
    5: CancelationReasonsEnum.TheftOrLossOfTheSim,  
    6: CancelationReasonsEnum.PrefersCompetitorProduct,  
    7: CancelationReasonsEnum.ServiceRelated,  
    8: CancelationReasonsEnum.NoCoverage,  
    9: CancelationReasonsEnum.FraudulentOrder,  
    10: CancelationReasonsEnum.CustomerDeceased,  
    11: CancelationReasonsEnum.DuplicateOrder,  
    12: CancelationReasonsEnum.OrderAbandoned,  
    13: CancelationReasonsEnum.UnableToActivateOnNetwork,  
  };  
  return mapping[id] || null;  
  }  

  async created() {
    this.userHasPermisson = await hasClaim("ManageOrders")
    this.actions.topUp.ddl = await topUpDdlGet();
    this.actions.cancel.ddl = await cancelDdlGet();
  }

  handleDate(date: any) {
    return formatDate(date);
  }

  handleName(name:string) {
    return formatName(name)
  }

  handleAction(product:OrderProductsDto, action: ActionName) {
    this.dialogSelectedProduct = product;
    this.dialogSelectedAction = action;
    this.handelActionDetail(product, action);    
    this.dialogVisible = true;
  }

  async handelActionDetail(product:OrderProductsDto, action: ActionName) {
    switch(action) {
      case "topUp": 
        this.actions[action].message = `<div><b>MSISDN:</b> ${product.msisdn}</div>`;
        this.dialogSelectedDdlId = this.actions[action].ddl?.[0].id || null;
        break; 
      case "migrate":
        this.actions[action].ddl = await migrateDdlGet(product.products.id);
        this.actions[action].message = `
          <div><b>MSISDN:</b> ${product.msisdn}</div>
          <div><b>Current:</b> ${ product.products.vascoDealDescription} @ R${ product.products.vascoPrice}pm</div>
        `
        // this.dialogSelectedDdlId = product.products.id;
        break; 
      case "cancel":
        this.actions[action].message = `
          <div><b>MSISDN:</b> ${product.msisdn}</div>
          <div>Are you sure you want to cancel this product?</div>
        `
        this.dialogSelectedDdlId = this.actions[action].ddl?.[0].id || null;
        break; 
      case "suspend":
        this.actions[action].message = `
          <div><b>MSISDN:</b> ${product.msisdn}</div>
          <div>Are you sure you want to suspend this product?</div>
        `
        this.dialogSelectedDdlId = null;
        break; 
      default: 
        break; 
    }
  }

  handleActionClose() {
    this.dialogSelectedProduct = null;
    this.dialogSelectedAction = null;
    this.dialogSelectedDdlId = null;
    this.actions.migrate.ddl = [];
    this.dialogVisible = false;
  }

  async topUp() {
    debugger
    if(this.dialogSelectedProduct?.msisdn) {
      const payload = {
        productsId: this.dialogSelectedDdlId as number,
        msisdn: this.dialogSelectedProduct.msisdn,
        isTopUp: true,
        ordersId: this.dialogSelectedProduct.ordersId
      }

      await ordersProductTopUpPut(this.dialogSelectedProduct.msisdn, payload)
      .then(() => {
        this.$emit('reload');
        this.handleActionClose();
      })
      .catch(() => {
        this.handleActionClose();
      }) 
    } else {
      Message.error({ message: "An error has occurred - MSISDN is required.", showClose: true })
      this.handleActionClose();
    }
  }

  async migrate() {
    debugger
    if(this.dialogSelectedProduct?.msisdn) {
      const payload = {
        productsId: this.dialogSelectedDdlId as number,
        msisdn: this.dialogSelectedProduct.msisdn,
        isTopUp: false,
        ordersId: this.dialogSelectedProduct.ordersId
      }

      await ordersProductMigratePut(this.dialogSelectedProduct.msisdn, payload)
      .then(() => {
        this.$emit('reload');
        this.handleActionClose();
      })
      .catch(() => {
        this.handleActionClose();
      }) 
    } else {
      Message.error({ message: "An error has occurred - MSISDN is required.", showClose: true })
      this.handleActionClose();
    }
  }

  async cancel() {
    debugger
    if (this.dialogSelectedProduct?.msisdn) {

      // Use the new method to get the correct enum value for the selected cancellation reason  
      const cancelationReasonEnumValue = this.getCancelationReasonEnum(Number(this.dialogSelectedDdlId));  
        
      // Validate that we have a valid enum value  
      if (!cancelationReasonEnumValue) {  
        Message.error({ message: "Invalid cancellation reason selected.", showClose: true });  
        return this.handleActionClose();  
      }  
      
      // Update the payload to use the enum value  
      const payload: ApiOrdersProductsMsisdnCancelDeleteRequest = {  
        msisdn: this.dialogSelectedProduct.msisdn,  
        cancelationReasonsId: cancelationReasonEnumValue,  
      }; 

        await ordersProductCancel(this.dialogSelectedProduct.msisdn, payload)
            .then(() => {
                this.$emit('reload');
                this.handleActionClose();
            })
            .catch(() => {
                this.handleActionClose();
            });
    } else {
        Message.error({ message: "An error has occurred - MSISDN is required.", showClose: true });
        this.handleActionClose();
    }
}

  async suspend() {
    debugger
    if(this.dialogSelectedProduct?.msisdn) {
      await ordersProductSuspend(this.dialogSelectedProduct.msisdn)
      .then(() => {
        this.$emit('reload');
        this.handleActionClose();
      })
      .catch(() => {
        this.handleActionClose();
      }) 
    } else {
      Message.error({ message: "An error has occurred - MSISDN is required.", showClose: true })
      this.handleActionClose();
    }
  }

  paymentHistory(product: OrderProductsDto): void {
    console.log('paymentHistory', product.id, product.ordersId);
    //paymentHistory
  }
}
