import { ApiOrdersProductsMsisdnCancelDeleteRequest, ApiOrdersProductsMsisdnTopupPutRequest, OrderMessagesDto, OrderProductsViewModel, OrdersViewModel, PagedResultOfOrdersViewModel, MessageType, PagedResultOfPaymentDueOrdersViewModel, ApiOrdersProcessPaymentsPostRequest, QueueStatus, ApiOrdersActivateOrderPutRequest, OrderProductGroupedQueuesViewModel, UpdatePaymentMethod, OrderStateHistoryViewModel } from "@/api-client";
import { Orders, OrderHistory } from "@/network/api";
import { handleError } from "@/utils/auth";
import { Message } from "element-ui";

export function ordersGet(
  pgNum: number = 1,
  pgSize: number = 20,
  filter?: string | undefined,
  orderIds: string[] = [],
  queueStatus?: QueueStatus | undefined
  ): Promise<PagedResultOfOrdersViewModel> {
  return new Promise((resolve, reject) => {
    window.scrollTo({ top: 0 });
    Orders.apiOrdersGet(pgNum, pgSize, filter, orderIds, queueStatus)
    .then((res) => {
      if ([200, 201, 204].includes(res.status)) {
        resolve(res.data)
      }
    })
    .catch((error) => {
      handleError(error);
      reject(error);
    });
  });
}

export function ordersPaymentsDueGet(
  pgNum: number = 1,
  pgSize: number = 20,
  billingDay: number,
  ): Promise<PagedResultOfPaymentDueOrdersViewModel> {
  return new Promise((resolve, reject) => {
    window.scrollTo({ top: 0 });
    Orders.apiOrdersPaymentsdueGet(+billingDay, pgNum, pgSize)
    .then((res) => {
      if ([200, 201, 204].includes(res.status)) {
        resolve(res.data)
      }
    })
    .catch((error) => {
      handleError(error);
      reject(error);
    });
  });
}

export function ordersQueuesGet(): Promise<OrderProductGroupedQueuesViewModel> {
  return new Promise((resolve, reject) => {
    window.scrollTo({ top: 0 });
    Orders.apiOrdersQueuesGet()
    .then((res) => {
      if ([200, 201, 204].includes(res.status)) {
        resolve(res.data)
      }
    })
    .catch((error) => {
      handleError(error);
      reject(error);
    });
  });
}


export function ordersGetId(id: string): Promise<OrdersViewModel> {
  return new Promise((resolve, reject) => {
    window.scrollTo({ top: 0 });
    Orders.apiOrdersIdGet(id)
    .then(async (res) => {
      if ([200, 201, 204].includes(res.status)) {
        resolve(res.data)
      }
    })
    .catch((error) => {
      handleError(error);
      reject(error);
    });
  });
}

export function orderHistoryGet(id: string): Promise<OrderStateHistoryViewModel[]> {
  return new Promise((resolve, reject) => {
    console.log(id)
    OrderHistory.apiOrderHistoryOrderIdGet(id)
    .then(async (res) => {
      if ([200, 201, 204].includes(res.status)) {
        resolve(res.data)
      }
    })
    .catch((error) => {
      handleError(error);
      reject(error);
    });
  });
}

export function ordersGetIdMessages(id: string, typeFilter: MessageType | ""): Promise<OrderMessagesDto[]> {
  return new Promise((resolve, reject) => {
    window.scrollTo({ top: 0 });
    Orders.apiOrdersIdMessagesGet(id, typeFilter || undefined)
    .then((res) => {
      if ([200, 201, 204].includes(res.status)) {
        resolve(res.data)
      }
    })
    .catch((error) => {
      handleError(error);
      reject(error);
    });
  });
}

export function ordersProcessPaymentsPost(item: ApiOrdersProcessPaymentsPostRequest): Promise<void> {
  return new Promise((resolve, reject) => {
    Orders.apiOrdersProcessPaymentsPost(item)
    .then((res) => {
      if ([200, 201, 204].includes(res.status)) {
        Message.success({ message: "Action successful!", showClose: true })
        resolve()
      }
    })
    .catch((error) => {
      handleError(error);
      reject(error);
    });
  });
}

export function ordersProductTopUpPut(msisdn: string, item: ApiOrdersProductsMsisdnTopupPutRequest): Promise<OrderProductsViewModel> {
  return new Promise((resolve, reject) => {
    Orders.apiOrdersProductsMsisdnTopupPut(msisdn, item)
    .then((res) => {
      if ([200, 201, 204].includes(res.status)) {
        Message.success({ message: "Action successful!", showClose: true })
        resolve(res.data)
      }
    })
    .catch((error) => {
      handleError(error);
      reject(error);
    });
  });
}

export function ordersProductMigratePut(msisdn: string, item: ApiOrdersProductsMsisdnTopupPutRequest): Promise<OrderProductsViewModel> {
  return new Promise((resolve, reject) => {
    Orders.apiOrdersProductsMsisdnMigrationPut(msisdn, item)
    .then((res) => {
      if ([200, 201, 204].includes(res.status)) {
        Message.success({ message: "Action successful!", showClose: true })
        resolve(res.data)
      }
    })
    .catch((error) => {
      handleError(error);
      reject(error);
    });
  });
}

export function ordersProductCancel(msisdn: string, cancelReasonId: ApiOrdersProductsMsisdnCancelDeleteRequest): Promise<void> {
  return new Promise((resolve, reject) => {
    Orders.apiOrdersProductsMsisdnCancelDelete(msisdn, cancelReasonId)
    .then((res) => {
      if ([200, 201, 204].includes(res.status)) {
        Message.success({ message: "Action successful!", showClose: true })
        resolve()
      }
    })
    .catch((error) => {
      handleError(error);
      reject(error);
    });
  });
}

export function ordersProductSuspend(msisdn: string): Promise<void> {
  return new Promise((resolve, reject) => {
    Orders.apiOrdersProductsMsisdnSuspendServiceDelete(msisdn)
    .then((res) => {
      if ([200, 201, 204].includes(res.status)) {
        Message.success({ message: "Action successful!", showClose: true })
        resolve()
      }
    })
    .catch((error) => {
      handleError(error);
      reject(error);
    });
  });
}

export function ordersActivate(item: ApiOrdersActivateOrderPutRequest): Promise<void> {
  return new Promise((resolve, reject) => {
    Orders.apiOrdersActivateOrderPut(item)
    .then((res) => {
      if ([200, 201, 204].includes(res.status)) {
        Message.success({ message: "Action successful!", showClose: true })
        resolve()
      }
    })
    .catch((error) => {
      handleError(error);
      reject(error);
    });
  });
}

export function ordersBypassTelkomSuspend(orderId: string): Promise<void> {
  return new Promise((resolve, reject) => {
    Orders.apiOrdersBypassTelkomSuspendOrderPut({orderId: orderId})
    .then((res) => {
      if ([200, 201, 204].includes(res.status)) {
        Message.success({ message: "Action successful!", showClose: true })
        resolve()
      }
    })
    .catch((error) => {
      handleError(error);
      reject(error);
    });
  });
}

export function orderPaymentMethodUpdate(updateRequest: UpdatePaymentMethod): Promise<void> {
  return new Promise((resolve, reject) => {
    Orders.apiOrdersPut(updateRequest)
    .then((res) => {
      if ([200, 201, 204].includes(res.status)) {
        Message.success({ message: "Action successful!", showClose: true })
        resolve()
      }
    })
    .catch((error) => {
      handleError(error);
      reject(error);
    });
  });
}